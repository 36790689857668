import "styles/pages/page-contact.scss";

import React from "react";
import styled from "styled-components";
import theme from "theme";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import NewContactForm from "components/NewContactForm";

const ContactDetails = styled.address`
  width: 100%;
  position: absolute;
  font-size: 12px;
  color: ${theme.colors.light};
  top: 250px;

  ${theme.screens.mdScreen} {
    top: 160px;
  }
  ${theme.screens.smScreen} {
    position: relative;
    top: unset;
    text-align: center;
    padding: 15px;
  }
`;

const DetailsFontColor = styled.span`
  color: ${theme.colors.light};

  ${theme.screens.mdScreen} {
  }
  ${theme.screens.smScreen} {
    color: ${theme.colors.blue};
    font-size: 16px;
  }
`;

const Contact = ({ pageContext }) => {
  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title="Contact"
        image={require("../assets/images/contact-img-bg.png").default}
      />
      <ContactDetails>
        <div className="container">
          <div className="row">
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 address__block">
              <DetailsFontColor>
                Prodinity Limited
                <br />
                134 Buckingham Palace Road,
                <br />
                London, SW1W 9SA
                <br />
                United Kingdom
                <br />
              </DetailsFontColor>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 address__block">
              <DetailsFontColor>
                Prodinity Sp. z o.o.
                <br />
                ul. Kazimierza Wielkiego 58/6
                <br />
                30-074 Kraków
                <br />
                Poland
              </DetailsFontColor>
            </div>
          </div>
        </div>
      </ContactDetails>
      {/* <ContactForm /> */}
      <NewContactForm />
    </Layout>
  );
};

export default Contact;
